@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;0,700;1,400;1,500;1,600&display=swap');
:root{
    --btn-color: #544af4;
    --light-btn-color: #2618ec;
}

body {
  background-color: rgb(16, 17, 37);
  margin: 0;
  padding: 0;
  oveflow-x: hidden;
  
}
* {
  font-family: 'Poppins', 'Open Sans', sans-serif;
  
  -webkit-tap-highlight-color: transparent;
}




:root{
    --btn-color: #544af4;
    --light-btn-color: #2618ec;
}
.about{
    margin: 0 auto;
    max-width: 1310px;
    padding-left: 70px;
    height: 100vh;
    padding-right: 70px;
}
.next-page-about{
    position: fixed;
    top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.next-page-about i {
    background-color: var(--light-btn-color);
    border-radius: 50px;
    font-size: 40px;
    color: white;
    cursor: pointer;
    padding: 5px;
}
.about h1{
    color: var(--btn-color);
    font-size: 50px;
}
.about h3{
    font-weight: 500;
    width: 800px;
    color: #a1a1a1;
}